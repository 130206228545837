<template>
	<div id="orgMng" v-loading.fullscreen.lock="loading" element-loading-text="请求中，请稍后"
		element-loading-background="rgba(255, 255, 255, 0.7)">
		<div class="c-container-box">
			<div class="tree-data">
				<el-tree :data="dataArr" :filter-node-method="filterNode" node-key="id" ref="treeData"
					:default-expanded-keys="['0']" draggable :allow-drop="allowDrop" @node-drag-start="nodeDragStart"
					@node-drop="nodeDrop">
					<!-- accordion 只打开一个同级树节点  -->
					<div class="custom-tree-node" slot-scope="{ node, data }">
						<i v-show="data.type!='org'" class="iconfont icon_device"></i>&nbsp;
						<span>{{data.title?data.title:data.uid}}</span>
						<span v-if="data.type=='org'">
							<i class="el-icon-circle-plus" @click="showAddDialog(node,'add')"></i>
							<i class="el-icon-info" @click="showAddDialog(node,'edit')" v-show="data.id!='0'"></i>
							<i class="el-icon-remove" @click="showDeleteDialog(node)" v-show="data.id!='0'"
								:style="{opacity:node.childNodes.length>0?'0.3':'1'}"></i>
						</span>
					</div>
				</el-tree>
			</div>
			<!-- <el-button type="primary" class="save">保存</el-button> -->
		</div>
		<!-- 添加节点dialog -->
		<Dialog :data="addDialog" @sure="onSure">
			<template>
				<div class="c-item">
					<span>组织名称：</span>
					<el-input v-model="addDialog.orgInputName"></el-input>
				</div>
			</template>
		</Dialog>
		<!-- 删除节点dialog -->
		<Dialog :data="deleteDialog" @sure="onSure">
			<template>
				您确定删除组织 “ {{selectedNode==""?"":selectedNode.data.title}} ” 吗？
			</template>
		</Dialog>
	</div>
</template>

<script>
	import Dialog from '../../components/Dialog.vue';

	export default {
		data() {
			return {
				copyDataArr: [], //用于拖拽失败还原
				// 添加节点dialog
				addDialog: {
					show: false,
					title: '添加组织',
					width: '400px',
					orgInputName: '',
				},
				// 删除节点dialog
				deleteDialog: {
					show: false,
					title: '删除组织',
					width: '400px'
				},
				selectedNode: '', //选中要操作的节点
				operateType: '', //操作类型：add添加，edit编辑，del删除
				loading: false
			}
		},
		components: {
			Dialog
		},
		computed: {
			dataArr() {
				return this.$store.state.treeData;
			}
		},
		methods: {
			// 过滤节点，只显示type=='org'的节点
			filterNode(value, data) {
				if (!value) return true;
				return data.type == value;
			},
			// 拖拽时判定目标节点能否被放置
			allowDrop(draggingNode, dropNode, type) {
				if (dropNode.data.type === 'device') {
					return type !== 'inner';
				}
				return type === 'inner';
			},
			// 拖拽开始和成功完成时
			nodeDragStart(draggingNode, event) {
				this.copyDataArr = JSON.parse(JSON.stringify(this.dataArr));
			},
			nodeDrop(draggingNode, dropNode, type) {
				let json = {
					"cmd": "moveToRegion",
					"parentRegionId": dropNode.data.id
				}
				if (draggingNode.data.type == "org") {
					json.regionId = draggingNode.data.id;
				} else {
					json.uid = draggingNode.data.id;
				}
				this.ws.send(json);
				this.loading = true;
			},

			showAddDialog(node, type) {
				this.selectedNode = node;
				this.operateType = type;
				this.addDialog.show = true;
				if (type == "add") {
					this.addDialog.title = "添加组织";
					this.addDialog.orgInputName = "";
				} else {
					this.addDialog.title = "编辑组织";
					this.addDialog.orgInputName = node.data.title;
				}
			},
			showDeleteDialog(node) {
				if (node.childNodes.length > 0) {
					this.common.cToast(this, "提示：该组织存在下级组织或存在设备，无法删除");
					return;
				}
				this.selectedNode = node;
				this.operateType = 'del';
				this.deleteDialog.show = true;
			},
			// 操作节点——添加/编辑/删除
			onSure() {
				switch (this.operateType) {
					case "add":
						this.append();
						break;
					case "edit":
						this.modify();
						break;
					case "del":
						this.remove();
						break;
					default:
						break;
				}
			},
			append() {
				let orgName = this.addDialog.orgInputName;
				if (orgName == "") {
					this.common.cToast(this, "请输入组织名称");
					return;
				}
				// 添加组织请求
				let data = this.selectedNode.data;
				let json = {
					"cmd": "addRegion",
					"parentRegionId": data.id,
					"title": orgName
				}
				this.ws.send(json);
				this.loading = true;
			},
			modify() {
				let orgName = this.addDialog.orgInputName;
				if (orgName == "") {
					this.common.cToast(this, "请输入组织名称");
					return;
				}
				// 编辑组织名称请求
				let data = this.selectedNode.data;
				let json = {
					"cmd": "editRegion",
					"regionId": data.id,
					"title": orgName
				}
				this.ws.send(json);
				this.loading = true;
			},
			remove() {
				// 删除组织请求
				let data = this.selectedNode.data;
				let json = {
					"cmd": "removeRegion",
					"regionId": data.id
				}
				this.ws.send(json);
				this.loading = true;
			}
		},
		mounted() {
			// setTimeout(() => {
			// 	this.$refs.treeData.filter("org");
			// }, 100)

			// 操作成功/失败
			const operateResult = (type, dialog) => {
				this.loading = false;
				if (type == "ok") {
					this.common.cResultOk(this);
				} else {
					this.common.cResultNo(this);
				}
				dialog.show = false;
			}

			// 添加组织result
			this.ws.addCallback("addRegionAck", (res) => {
				if (!this.common.cGetResult(res)) {
					operateResult('no', this.addDialog);
				}
			})
			this.ws.addCallback("regionAdded", (res) => {
				let data = this.selectedNode.data;
				const newChild = {
					id: res.regionId,
					title: res.title,
					children: [],
					type: 'org'
				};
				if (!data.children) {
					this.$set(data, 'children', []);
				}
				data.children.push(newChild);
				operateResult('ok', this.addDialog);
			})

			// 编辑组织名称result
			this.ws.addCallback("editRegionAck", (res) => {
				if (!this.common.cGetResult(res)) {
					operateResult('no', this.addDialog);
				}
			})
			this.ws.addCallback("regionEdited", (res) => {
				let data = this.selectedNode.data;
				data.title = res.title;
				operateResult('ok', this.addDialog);
			})

			// 删除组织result
			this.ws.addCallback("removeRegionAck", (res) => {
				if (!this.common.cGetResult(res)) {
					operateResult('no', this.deleteDialog);
				}
			})
			this.ws.addCallback("regionRemoved", (res) => {
				let node = this.selectedNode;
				const parent = node.parent;
				if (parent) {
					const children = parent.data.children || parent.data;
					const index = children.findIndex(d => d.id === node.data.id);
					children.splice(index, 1);
				}
				operateResult('ok', this.deleteDialog);
			})

			// 移动组织位置result
			this.ws.addCallback("moveToRegionAck", (res) => {
				if (!this.common.cGetResult(res)) {
					this.$store.commit('setTreeData', this.copyDataArr);
					this.common.cResultNo(this);
					this.loading = false;
				}
			})
			this.ws.addCallback("regionMoved", (res) => {
				this.common.cResultOk(this);
				this.loading = false;
			})
			this.ws.addCallback("deviceMoved", (res) => {
				this.common.cResultOk(this);
				this.loading = false;
			})
		}
	}
</script>

<style lang="scss">
	#orgMng {

		.c-container-box {
			margin-top: 0;
			display: flex;
			flex-direction: column;
			height: calc(100vh - 150px);
			box-sizing: border-box;

			.tree-data {
				flex: auto;
				overflow: auto;
			}

			.el-button {
				height: 40px;
			}
		}

		.custom-tree-node {
			// width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			padding-right: 8px;

			i {
				font-size: 16px;
			}

			i[class='el-icon-circle-plus'] {
				color: $color_theme;
				margin-left: 30px;
			}

			i[class='el-icon-info'] {
				color: $color_green;
				margin-left: 5px;
			}

			i[class='el-icon-remove'] {
				color: $color_red;
				margin-left: 5px;
			}

			i[class='iconfont icon_device'] {
				color: inherit;
				margin-left: 0;
				padding-right: 5px;
			}
		}

		// 重写element样式
		.el-tree-node__content {
			border-bottom: 1px solid $border_color;
			padding: 12px 0;
		}

		// 重写dialog样式
		.dialog-content {
			padding: 0 10%;
		}
	}
</style>
